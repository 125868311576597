<template>
  <v-app class>
    <div>
      <v-alert v-model="alert"
               border="left"
               dark
               dismissible
               class="alert"
               :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}">
        {{alertMessage}}
      </v-alert>
    </div>


    <div class="row">
      <div class="col-md-10 ml-auto mr-auto">
        <v-container>
          <div class="mb-8 mt-8 text-center">
            <h1>Personnel List Report</h1>
          </div>

          <div class="row text-center">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-5">
                  <SelectField :items="Status"
                               ref="inputstatus"
                               v-model="Status"
                               :label="'Status'"
                               :itemText="'name'"
                               :itemValue="'id'"
                               :returnObject="true" />

                </div>
                <div class="col-md-5">
                  <SelectField :items="Reasonforexits"
                               ref="inputreasonforexit"
                               v-model="formData.Reasonforexit"
                               :label="'Reason for exit'"
                               :itemText="'name'"
                               :itemValue="'id'"
                               :returnObject="true"
                               ::reset-on-options-change='true'/>
                </div>
                <div>
                  <Button :btnType="'Submit'" :isLoading="isSubmit" :label="'Submit'" @onClick="onSubmit" class="col-md-2" />
                </div>
              </div>
            </div>

          </div>


          <div class="col-md-8 d-flex justify-start">
            <div class="row">
              <div class="col-md-6 export-container">
                <SelectField :items="downloadFormat" v-model="selectedFormat" :label="'Download Format'" :itemText="'name'" :itemValue="'id'" :returnObject="true" @input="onSelectedFormat" />
                <Button :btnType="'Submit'" :isLoading="isDownloading" :label="'Export'" @onClick="onDownload" class="col-md-3 mr-2" />
              </div>
            </div>
          </div>




          <!--<div class="row">

    <div class="col-md-6">
      <SelectField :items="Status"
                   v-model="Status"
                   :label="'Status'"
                   :itemText="'name'"
                   :itemValue="'id'"
                   :returnObject="true"
                   @onChange="" />
    </div>

    <div class="col-md-6">
      <div class="export-container">
        <SelectField :items="downloadFormat" v-model="formData.selectedFormat" :label="'Download Format'" :itemText="'name'" :itemValue="'id'" :returnObject="true" @input="onSelectedFormat" />
      </div>
      <Button :label="'Download'" :btnType="'Submit'" @onClick="onDownload" class="" />
    </div>

  </div>-->







          <v-data-table loading-text="Loading personnel. Please wait...."
                        :loading="isLoading"
                        :headers='headers'
                        :items="employeeList"
                        :search="searchPersonnel"
                        item-key="id">
            <template v-slot:item.lastModifiedDate="{ item }">
              {{item.lastModifiedDate | formatDate}}
            </template>
            <template v-slot:item.firstName="{ item }">
              <span>{{ item.firstName }}, {{ item.lastName }} {{ item.middleName}}</span>
            </template>
          </v-data-table>

          <table hidden id="filteredpersonnellist">
            <thead>
              <tr>
                <th>Personnel Name</th>
                <th>Prnumber</th>
                <th>Charge Code</th>
                <th>Job Title</th>
                <th>Email</th>
                <th>Location</th>
                <th>Department</th>
                <th>Contractor Employer</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in employeeList" :key="item.requestId">
                <td>
                  {{item.firstName}}
                </td>
                <td>
                  {{item.prnumber}}
                </td>
                <td>
                  {{item.chargeCode}}
                </td>
                <td>
                  {{item.jobTitle.name}}
                </td>
                <td>
                  {{item.email}}
                </td>
                <td>
                  {{item.location.name}}
                </td>
                <td>
                  {{item.department.name}}
                </td>
                <td>
                  {{item.contractEmployer.name}}
                </td>
                <td>
                  {{item.employeeStatus}}
                </td>
              </tr>
            </tbody>
          </table>
        </v-container>
      </div>
    </div>


  </v-app>
</template>
<script>

import SelectField from '@/components/ui/form/SelectField.vue'
import Button from '@/components/ui/form/Button.vue'
  import { userService, employeeService, contractEmployerService, benefitService } from '@/services'
  import Roles from '@/utils/roles'
  import ExportToCSVFile from '@/utils/helper'
  import moment from 'moment'
  /*import XLSX from 'xlsx'*/
  import * as XLSX from 'xlsx/xlsx.mjs';
  import JSPDF from 'jspdf'
  import 'jspdf-autotable'


export default {
  components: {
    SelectField,
    Button
    // TextField,
    // Dialog
  },
  data () {
    return {
      search: '',
      userRole: '',
      appRoles: {},
      isLoading: false,
      formData: {
        name: '',
        id: '',
        Reasonforexit:''
      },
      contractEmployerId: 0,
      employeeList: [],
      excelExportRecords: [],
      Temp:[],
      contractEmployers: [],
      personnel: {},
      searchPersonnel: '',
      Status: '',
      Reasonforexits:[],
      valid: false,
      isSavingUser: false,
      alertMessage: '',
      allLoading: 0,
      alert: false,
      alertType: '',
      headers: [      
        {
          text: 'Personnel Name',
          value: 'firstName'
        },
        {
          text: 'Prnumber',
          value: 'prnumber'
        },
        {
          text: 'Charge Code',
          value: 'chargeCode'
        },
        {
          text: 'Job Title',
          value: 'jobTitle.name'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Location',
          value: 'location.name'
        },
        {
          text: 'Department',
          value: 'department.name'
        },
        {
          text: 'Contractor Employer',
          value: 'contractEmployer.name'
        },
        {
          text: 'Status',
          value: 'employeeStatus'
        }
        // {
        //   text: 'Modified By',
        //   value: 'modifiedBy',
        //   sortable: false
        // },
        // {
        //   text: 'Date Modified',
        //   value: 'lastModifiedDate'
        // }
      ],
      Status: [
        { id: 0, name: 'Active' }, { id: 1, name: 'Inactive' }
      ],     
      downloadFormat: [
        {
          name: 'Excel',
          id: 'excel'
        },
        {
          name: 'CSV',
          id: 'csv'
        },       
        {
          name: 'PDF',
          id: 'pdf'
        }
      ],
      selectedFormat: ''
    }
  },
  computed: {
    
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => { this.alert = false }, 5000)
    },
    edit (item) {
      this.$router.push(`/personnel-data/edit/${item.id}`)
    },
    openDeleteModal (item) {
      this.personnel = item
      this.$refs.deleteModal.toggleModal()
      this.formData.name = `${item.firstName}, ${item.lastName} ${item.middleName}`
    },
    resetForm () {
      this.formData.name = ''
      this.formData.id = ''
    },
    cancel (modal) {
      if (modal === 'editModal') {
        this.$refs.editModal.toggleModal()
      }
      if (modal === 'deleteModal') {
        this.$refs.deleteModal.toggleModal()
      }
    },      
    onSelectedFormat (value) {
      this.selectedFormat = value.id
    },
    onDownload() {
      try {
        this.isDownloading = true
        const time = moment().format()        
        switch (this.selectedFormat) {
          case 'pdf':
            const doc = new JSPDF('landscape')
            doc.autoTable({
              theme: 'striped',
              headStyles: { fillColor: '#0B2D71', textColor: '#fff' },
              alternateRowStyles: { fillColor: '#F3F4F8' },
              html:'#filteredpersonnellist'
            })
            doc.text('FilteredPersonnelList - CMMS', 150, 10, { align: 'center' })
            doc.save(`FilteredPersonnelList_${time}.pdf`)
            this.isDownloading = false
            break

          case 'excel':
            const formattedexcelData = []

            this.excelExportRecords.forEach(item => {
              formattedexcelData.push({
                PersonnelName: item.firstName.replace(/,/g, ''),
                Prnumber: item.prnumber.replace(/,/g, ''),
                ChargeCode: item.chargeCode.replace(/,/g, ''),
                JobTitle: item.jobTitle.name.replace(/,/g, ''),
                Email: item.email.replace(/,/g, ''),
                Location: item.location.name,
                Department: item.department.name,
                ContractorEmployer: item.contractEmployer.name,
                Status: item.employeeStatus
              })
            })            
            var PersonnelList = XLSX.utils.json_to_sheet(formattedexcelData)
            var PersonnelListWorkBook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(PersonnelListWorkBook, PersonnelList, 'FilteredPersonnelList')
            XLSX.writeFile(PersonnelListWorkBook, `FilteredPersonnelList_${time}.xlsx`)
            this.isDownloading = false
            break

          case 'csv':
            const formattedData = []
            const fileHeaders = {
              PersonnelName: 'Personnel Name',
              Prnumber: 'Prnumber',
              ChargeCode: 'Charge Code',
              JobTitle: 'Job Title',
              Email: 'Email',
              Location: 'Location',
              Department: 'Department',
              ContractorEmployer: 'Contractor Employer',
              Status:'Status'
            }          

            this.excelExportRecords.forEach(item => {
              formattedData.push({
                PersonnelName: item.firstName.replace(/,/g, ''),
                Prnumber: item.prnumber.replace(/,/g, ''),
                ChargeCode: item.chargeCode.replace(/,/g, ''),
                JobTitle: item.jobTitle.name.replace(/,/g, ''),
                Email: item.email.replace(/,/g, ''),
                Location: item.location.name,
                Department: item.department.name,
                ContractorEmployer: item.contractEmployer.name,
                Status: item.employeeStatus
              })
            })            
            ExportToCSVFile(fileHeaders, formattedData, `FilteredPersonnelRequest_${time}`)
            this.isDownloading = false
            break

          default:
            break
        }

      } catch (error) {
        this.isDownloading = false
      }

    },
    onSubmit() {      
      const data = {
        StatusId: this.Status.id,
        ReasonforexitId: this.formData.Reasonforexit.id       
      }     
      const excelRecords = []
      employeeService.getPersonnelReport(data).then(result => {      

        this.employeeList = result.data.items
        this.excelExportRecords=result.data.items       
        this.isLoading = false        
      }).catch(() => {
        this.isLoading = false
      })
    }
  },
  mounted () {
    this.isLoading = false
    this.appRoles = Roles

    userService.getCurrentUser().then(result => {
      this.userRole = result.data.roleName
    })

    contractEmployerService.getAllContractEmployers().then((result) => {
      this.contractEmployers = result.data.items
      this.contractEmployers.unshift({ 'id': 0.1, 'name': 'All' })
    })

    benefitService.getExitReasons().then(result => {
      this.Reasonforexits = result.data      
    })

  }
}
</script>
<style scoped>
.export-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
